<template>
    <div>
        <ts-page-title :title="$t('inventoryPendingList.pageTitle')" :breadcrumb="[
            { text: $t('home'), href: '/' },
            {
                text: $t('inventoryPendingList.pageTitle'),
                active: true
            }
        ]" />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <div class="tw-flex tw-space-x-3">
                        <a-range-picker v-model="dateRange" :allowClear="false" format="DD-MM-YYYY" valueFormat="DD-MM-YYYY"
                            style="width: 200px" @change="fetchData()" />
                        <a-select v-if="!$whois.shop()" show-search v-model="shop_id"
                            :placeholder="$t('inventoryPendingList.shopName')" style="width: 200px"
                            :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                            :not-found-content="null" :loading="shop_loading" @search="handleSearch"
                            @change="onCustomerChange">
                            <a-select-option v-for="s in shopList" :key="s.shop_id">
                                {{ s.shop_name }}
                            </a-select-option>
                        </a-select>
                        <ts-button class="btn-block" color="primary" @click.prevent="preview" :waiting="waiting"><i
                                class="fas fa-file-pdf text-red"></i>
                            {{ $t('pdf') }}</ts-button>
                    </div>
                    <a-tooltip placement="topLeft" :title="$t('inventoryPendingList.search')">
                        <a-input v-model="search" v-tooltip="$t('inventoryPendingList.search')"
                            :placeholder="$t('inventoryPendingList.search')" style="width: 250px" class="inline-block" />
                    </a-tooltip>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table :columns="columns" :records="resources" :loading="loading">
                    <template v-slot="{ record }">
                        <td>{{ record['barcode'] }}</td>
                        <td>{{ record['packaging_number'] }}</td>
                        <td>{{ record['shop_name'] }}</td>
                        <td>{{ record['product_name_kh'] }}</td>
                        <td>{{ record['product_name_en'] }}</td>
                        <td>{{ record['driver_name'] }}</td>
                        <td>{{ record['voucher_no'] }}</td>
                        <td>{{ record['booking_number'] }}</td>
                        <td>{{ record['pending_quantity'] }}</td>
                        <td>{{ record['receiver_phone'] }}</td>
                    </template>
                </ts-table>
            </div>
            <a-modal :title="$t('pdf')" width="50%" v-model="show_pdf" :footer="null">
                <ts-preview-pdf v-model="waiting" :src="src" />
            </a-modal>

            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination v-model="pagination" @navigate="fetchData"></ts-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { debounce } from "lodash";
import { mapState, mapActions } from "vuex";
export default {
    name: "inventory-pending-list",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            shop_loading: false,
            waiting: false,
            show_pdf: false,
            shop_id: undefined,
            src: null,
            shopList: []
        };
    },
    computed: {
        ...mapState("inventory/inventoryPendingList", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.inventory.inventoryPendingList.search;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/inventoryPendingList/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "inventory/inventoryPendingList/RESET_CURRENT_PAGE"
                );
            }
        },
        dateRange: {
            get() {
                return this.$store.state.inventory.inventoryPendingList.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/inventoryPendingList/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("inventoryPendingList.barcode"),
                    style: "text-align:center", sortKey: 'barcode'
                },
                {
                    name: this.$t("inventoryPendingList.packakgingNumber"),
                    style: "text-align:center", sortKey: 'packaging_number'
                },
                { name: this.$t("inventoryPendingList.shopName"), sortKey: 'shop_name' },
                { name: this.$t("inventoryPendingList.productNameKh"), sortKey: 'product_name_kh' },
                { name: this.$t("inventoryPendingList.productNameEN"), sortKey: 'product_name_en' },
                { name: this.$t("inventoryPendingList.driverName"), sortKey: 'driver_name' },
                {
                    name: this.$t("inventoryPendingList.voucherNo"),
                    style: "text-align:center", sortKey: 'voucher_no'
                },
                {
                    name: this.$t("inventoryPendingList.bookingNumber"),
                    style: "text-align:center", sortKey: 'booking_number'
                },
                {
                    name: this.$t("inventoryPendingList.pending"),
                    style: "text-align:center", sortKey: 'pending_quantity'
                },
                {
                    name: this.$t("inventoryPendingList.receiverPhone"),
                    style: "text-align:center", sortKey: 'receiver_phone'
                }
            ];
        }
    },

    methods: {
        ...mapActions('inventory/inventoryPendingList', ['getFormViewData']),
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("inventory/inventoryPendingList/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        handleSearch: debounce(function (value) {
            this.shop_loading = true;
            this.getFormViewData({
                params: {
                    search: value
                }
            })
                .then(response => {
                    this.shopList = response.data.shop;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.shop_loading = false;
                });
        }, 1000),
        onCustomerChange() {
            this.fetchData({ shop_id: this.shop_id });
        },
        preview() {
            this.show_pdf = true
            this.waiting = true
            this.$store
                .dispatch('inventory/inventoryPendingList/preview')
                .then(response => {
                    this.src = response.url
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                })
                .finally(() => {
                    this.waiting = false
                })
        },
    },
    watch: {
        search: debounce(function () {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("inventory/inventoryPendingList/RESET_STATE");
        next();
    }
};
</script>
